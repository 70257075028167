<template>
  <ui-component-modal
    modalTitle="Stop location"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="stopLocation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        <div class="has-text-weight-bold">
          Are you sure you want to stop this location?
        </div>
        <div>
          This will automatically unpublish the location on all channels and
          stop all subscriptions. Only stop the location if you are really sure.
        </div>
      </div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="'Yes, I am sure!'"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'

export default {
  components: {
    BaseCheckbox: () => import('@/components/UI/Form/BaseCheckbox'),
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {},

  methods: {
    ...mapMutations('locationStore', ['setLocationState']),

    stopLocation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        locationProvider.methods
          .stopLocation(this.locationState.Id)
          .then((response) => {
            if (response.status === 200) {
              self.setLocationState(response.data)
              self.onClickCancel()
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
